$body-color: #fff;

body {
  background-color: $body-color;
  color: #222;
 
}

.has-background-primary {
  background-color: $primary !important;
}

.jarallax {
  min-height: 80vh;
}

.column .jarallax {
  min-height: 50vh;
}

p {
  line-height: 1.8em;
}

.column {
  padding: 2em 0.75em;
}

.column.is-6 {
  padding: 2.75em;
}



